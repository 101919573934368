<template>
<el-form v-loading='loading'>
  <div style="overflow-x:auto;">
    <div class="filter-container" style="background: #fff;padding: 15px;">
      <div class="filter-item">
        <label class="label">关键字: </label>
        <el-input v-model="keyWords" @keyup.enter.native="goodsSearch" placeholder="商品名称" style="width: 250px;;margin-right: 10px;" clearable></el-input>
      </div>
      <div class="category">
        <label class="label">所有类目: </label>
        <el-checkbox-group v-model="checkList" @change="handleCheckedChange">
            <el-checkbox v-for="item in categoryList" :label="item.Id" :key="item.Id">{{item.CategoryName}}</el-checkbox>
          </el-checkbox-group>
      </div>
      <div class="sort-container">
        <div class="sort-nav" :class="{'active-sort':currentSort=='ProfitRate'}" @click="handleChangeSort('ProfitRate')">利润</div>
        <div class="sort-nav" :class="{'active-sort':currentSort=='SellCountAll'}" @click="handleChangeSort('SellCountAll')">销量</div>
        <div class="sort-nav" :class="{'active-sort':currentSort=='SupplierMallPrice'}" @click="handleChangeSort('SupplierMallPrice')">供货价</div>
      </div>
      <div  class="table-container" style="margin-top:20px">
          <div class="goods-list">
            <div class="goods-item" v-for="(item,index) in dataList" :key='index'>
              <div class="item-img">
                <img style="width:160px;height:160px;object-fit:cover;" :src='imgUrl+item.ImgUrl '>
                <div class="img-mask">
                  <span style="margin-right:10px;">销量</span>
                  <span v-if='item.SellCountAll >100000||item.SellCountAll ==100000'>{{Math.floor(item.SellCountAll /10000)}}万+件</span>
                  <span v-else>{{item.SellCountAll }}件 </span>
                </div>
              </div>
              <div class="item-name" >{{item.Name}}</div>
              <div class="item-price" >供货价：￥{{item.SupplierMallPrice}} </div>
              <div class="item-price" > 
                利润：
                <span style="color:#F56C6C;">{{item.ProfitRate}}%</span>
              </div>
              <div class="item-button" v-if='!item.IsAddedToMall'>
                <div class="button-left" @click="handleAddWarehouse(item.MallId,item.Id)">添加到仓库</div>
                <div class="button-right" @click="handleAddMarket(item.MallId,item.Id)">上架到商城</div>
              </div>
              <div class="item-button" v-else>已添加到商城 </div>
            </div>
          </div>
        <div v-if='!page.total' style='line-height: 60px;color: #909399;text-align:center;width:100%;'>暂无数据</div>
        <div style="text-align: right;">
          <el-pagination v-if="page.total"  @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
  </el-form >
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	import {
		productCategoryLibraryList
	} from '@/api/goods'
	export default {
		data() {
			return {
        loading:false,
        keyWords:'',
        dataList:[],
        page:{
          total:0,
          current:1,
          size:40
        },
        categoryList:[],
        checkList: [],
        imgUrl: config.IMG_BASE,
        currentSort:'ProfitRate',
      }
		},
		created() {
      this.getCategory()
			this.getList()
		},
		methods: {
			//获取数据
			async getList() {
        this.loading = true
        let IsAsc = this.currentSort=='SupplierMallPrice'? true : false
				try {
          let data = {
            Keywords: this.keyWords,
            CategoryIdList: this.checkList,
            Skip: (this.page.current - 1) * this.page.size, // 略过数据
            Take: this.page.size, // 取的数据
            OrderBy :this.currentSort,
            IsAsc :IsAsc
          }
          let result = await apiList.supplierMarketProductList(data)
					this.page.total = result.Result.Total;
					this.dataList = result.Result.Results || [];
				} catch (e) {
					console.log('e',e)
					this.loading = false
				} finally {
					this.loading = false
				}
      },
      async getCategory() {
        let result= await productCategoryLibraryList()
				this.categoryList = result.Result
      },
      handleCheckedChange(){
        this.getList()
      },
			goodsSearch() {
			  this.page.current = 1;
				this.getList();
      },
      handleChangeSort(v){
        this.currentSort = v
			  this.page.current = 1
        this.getList()
      },
      async handleAddWarehouse(MallId,Id){
        this.loading = true
        try {
          let result = await apiList.supplierMarketProductSave({
            SupplierMallId: MallId,
            SupplierProductId: Id,
            IsOpen: false
          })
          if(result.IsSuccess){
            this.$message({
              showClose:true,
              type:'success',
              message:'操作成功'
            })
            this.getList()
          }else{
					  this.loading = false
          }
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
        }
      },
      async  handleAddMarket(MallId,Id){
        this.loading = true
        try {
          let result = await apiList.supplierMarketProductSave({
            SupplierMallId: MallId,
            SupplierProductId: Id,
            IsOpen: true
          })
          if(result.IsSuccess){
            this.$message({
              showClose:true,
              type:'success',
              message:'操作成功'
            })
            this.getList()
          }else{
					  this.loading = false
          }
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
        }
      },
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
			  this.page.current = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
.filter-container{
  width: 1660px;
  margin: 0 auto;
}
.category{
  padding:10px;
  border:1px solid #ddd;
}
.sort-container{
  background: #ddd;
  border:1px solid #ddd;
  height: 32px;
  margin-top:20px;
}
.sort-nav{
  cursor:pointer;
  display:inline-block;
  line-height: 30px;
  text-align: center;
  width: 100px;
}
.active-sort{
  background-color: #fff;
}
.goods-list{
  .goods-item{
    font-size:12px;
    width:160px;
    overflow: hidden;
    display:inline-block;
    margin-right:50px;
    margin-bottom:30px;
    .item-img{
      width:160px;
      height:160px;
      position:relative;
      text-align:center;
      .img-mask{
        color:#fff;
        bottom:0;
        position:absolute;
        width:100%;
        line-height:30px;
        background-color:rgba(0,0,0,0.5)
      }
    }
    .item-name{
      width:100%;height: 26px;
      display:-webkit-box;
	    -webkit-line-clamp: 2; 
      overflow: hidden;
      -webkit-box-orient: vertical;
      margin:7px 0;
    }
    
    .item-price{
      margin-bottom:7px;
      font-size:12px;
    }
    .item-button{
      width:100%;
      background-color:#F56C6C;
      line-height:30px;
      text-align:center;
      color:#fff;
      .button-left{
        cursor:pointer;
        color:#fff;
        width:50%;line-height:30px;display:inline-block;
        text-align:center;
        background-color:#409EFF;
      }
      .button-right{
        cursor:pointer;
        color:#fff;
        width:50%;
        line-height:30px;
        display:inline-block;
        text-align:center;
        background-color:#2089F4;
      }
    }
  }
  .goods-item:nth-child(8n){
    margin-right: 0px;
  }
}
</style>
